<template>
  <div class="landing">
    <Header
      v-model="isOpen"
      class="landing-content"
    />
    <main>
      <MainBlock
        class="landing-content"
        :i18n="i18n.mainBlock"
        :is-open="isOpen"
      />
      <div class="landing-stripe advantages__wrap">
        <Advantages
          class="landing-content"
          :i18n="i18n.advantages"
        />
      </div>
      <HowGet
        id="howget"
        class="landing-content"
        :i18n="i18n.howGet"
      >
        <div class="landing-stripe py-5">
          <Conditions
            class="landing-content"
            :i18n="i18n.conditions"
          />
        </div>
        <Feedback :i18n="i18n.feedback" />
      </HowGet>
      <div
        id="conditions"
        class="landing-stripe py-5"
      >
        <Conditions
          class="landing-content"
          :i18n="i18n.conditions"
        />
      </div>
      <Feedback :i18n="i18n.feedback" />
      <Questions
        id="questions"
        class="landing-content"
        :i18n="i18n.questions"
      />
    </main>
  </div>
</template>

<style lang="scss">
.landing-content {
  max-width: 1250px;
  margin: 0 auto;
  width: 100%;
}

.landing .header .logo {
  margin-left: 15px;
}

@media (max-width: 767px) {
  .landing .header {
    padding-top: 0;
  }

  .landing .how-get-content__text {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .landing .header {
    padding-left: 0;
    border: none;
  }
}

.landing-stripe {
  display: flex;
  justify-content: center;
  background: #2979FF;
}

</style>

<script>
import Header from "./components/header/header.vue";
import MainBlock from "./components/main-block/main-block.vue";
import Advantages from "./components/advantages/advantages.vue";
import landingI18nRu from "./landing.i18n.ru.js";
import AnketaMixin from "@frontend/fin-recurrent-main/mixins/anketa.js";
import { historyPush } from "@frontend/fin-recurrent-main/services/combacker";

export default {
  name: "Landing",
  components: {
    Header,
    MainBlock,
    Advantages,
    HowGet: () => import("./components/how-get/how-get.vue"),
    Conditions: () => import("./components/conditions/conditions.vue"),
    Feedback: () => import("./components/feedback/feedback.vue"),
    Questions: () => import("./components/questions/questions.vue"),
  },
  mixins: [AnketaMixin],
  data() {
    return {
      workflow: "direct",
      i18n: landingI18nRu,
      isOpen: false,
    };
  },
  mounted(){
    this.$infra.finvin
      .init(this.workflow)
      .then((res) => {
        this._setGeneralState(res);
      })
      .catch((e) => {
        this.res = e;
      })
      .then(() => {
        historyPush();
      });
  }
};
</script>
